import VxgModelVue from '@voxgig/model-vue'

import { init } from '../node_modules/@plantquest/frontend/src/init'
import aws from './aws-exports'
import td from './pqd-pq01-ast-014.json'
import model from '../model/model.json'

console.log('D02')

const info = {
  node_env: process.env.NODE_ENV,
  app: {
    system: 'demo',
    name: 'admwa01',
    version: '0.5.0',
    note: 'aaa',
  },
  VxgModelVue: VxgModelVue.info,
  PqsFrontend: init.info,
}

const custom = {

  map_dict: {
    // 'pqd-pq01-m01-011': 'Demo facility Ground Floor',
    // 'pqd-pq01-m02-011':  'Demo facility First Floor',
    'pqd-pq01-m01-013': 'Demo facility Ground Floor',
    'pqd-pq01-m02-013':  'Demo facility First Floor',
  },

  filters: [
    { name:'building', title:'Building', show:true, value:'', items:[] },

    // TODO: spelling!
    // { name:'dicipline1', title:'Discipline', show:true, value:'', items:[] },

    // TODO: custom?
    // { name:'systemtype', title:'System Type', show:true, value:'', items:[] },

    { name:'level', title:'Level', show:true, value:'', items:[] },

    { name:'atype', title:'Asset Type', show:true, value:'', items:[] },


    // { name:'system', title:'System', show:true, value:'', items:[] },
    // { name:'subsys', title:'Sub-System', show:true, value:'', items:[] },

    // TODO: manfacturer
    // { name:'custom2', title:'Manufacturer', show:true, value:'', items:[] },

    { name:'drawing1', title:'Drawing No.', show:true, value:'', items:[] },

    // TODO: model
    // { name:'custom8', title:'Model', show:true, value:'', items:[] },
  ],
  // custom datasets depending on a plant
  datasets: { 
    'tag': 'tag',
    'atype': 'atype',
    'description': 'description',
    'dicipline1': 'discipline',
    'room': 'room',
    'level': 'level',
    'model': 'model',
    'manufacturer': 'manufacturer',
    'system': 'system',
    'subsys': 'subsys',
    'serial': 'serial',
    'drawing1': 'drawing1',
    'drawing2': 'drawing2',
    'building': 'building',
    'custom12': 'room_alias',
  },
  
  mixpanel: {
    project_token: 'bbdbc44055174019937323fc6ed6f353',
  },
  mixpanel_front_events: {
    'sign_in': {},
    'PQView_search': {},
    'Go_filter': {},
    'polygon_selection': {},
    'work_pack_save': {},
    'work_pack_load': {},
    'asset_update': {},
    'new_user': {},
    'show_tags': {},
    'cluster_tags': {},
  },

}

console.log('APP: demwa01',
            'map:009',
            'data:009a',
            info,
            model,
            td)


init({
  info,
  aws,
  custom,
  td,
  model,

  // Use JSON data file
  upload: false,
  
  map: {
    prefix: 'pqd-pq01-m0',
    suffix: '-011.png',
    width: 7800,
    height: 5850,
    minZoom: -3,
  },
  seneca: {
    endpoint: 'development' === process.env.NODE_ENV ? '/msg01' :
      aws.aws_cloud_logic_custom[0].endpoint+'/msg01'
   }
})

